








































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  doctorsPatientList,
  doctortexport,
  doctortimport,
  doctorsPatientAdd,
} from "@/apis";
import { parseTime } from "@/utils";
import fileDownload from "js-file-download";
//import Edit from "@/views/ActionCenter/ActivityManagement/module/Edit.vue";
@Component({
  components: {},
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class PatientView extends Vue {
  tableData: Array<any> = [];
  formData = {
    name: "", // 姓名
    sex: "", // 性别
    age: "", // 年龄
    disease: "", // 疾病诊断
    diseaseyear: "", // 病程长度
    location: "", // 所在地区
    channels: [], // 获知疗法渠道
  };
  dialogFormVisible = false;
  formList = {
    name: "", // 姓名
    age: "", // 年龄
    channel: "", // 渠道
    disease: "", // 疾病诊断
    diseaseyear: "", // 病程
  };
  file = {
    fileId: "",
    file: "",
  } as any;
  // 分页
  form = {
    cur_page: 1,
    page_size: 10,
  };

  totalSize = 0;
  created() {
    this.requestList();

    // 自动聚焦
    this.$nextTick(() => {
      (this.$refs.nameInput as any).focus();
    });
  }
  viewDetail(v: any) {
    console.log(v);
    this.$router.push({
      path: "/doctor-system/medical/patients/detail",
      query: { id: v.id_number },
    });
  }
  handleSizeChange(val: number) {
    console.log(`每页 ${val} 条`);
    this.form.cur_page = 1;
    this.form.page_size = val;
    this.requestList();
  }
  //取消添加:
  cancelButton() {
    this.dialogFormVisible = false;
    this.file = {
      fileId: "",
      file: "",
    };
  }
  async doctorsPatientAdd() {
    const temp = {} as any;
    const Api: any = this.formData; //重新定义了下循环的对象
    for (const key in Api) {
      if (Api[key]) {
        temp[key] = Api[key];
      }
    }
    if (Object.values(temp)[0] != "") {
      const result = await doctorsPatientAdd(temp);
      if (result.success) {
        this.requestList();
        this.tableData = result.data;
      }
    } else {
      this.$message.warning("请输入患者资料");
    }
  }
  addPatient() {
    this.dialogFormVisible = true;
  }
  handleCurrentChange(val: number) {
    console.log(`当前页: ${val}`);
    this.form.cur_page = val;
    this.requestList();
  }
  // 查询列表
  async requestList() {
    const result = await doctorsPatientList(this.form);
    if (result.success) {
      this.tableData = result.data;
      this.totalSize = result.page.total_size;
      console.log(result);
    }
  }

  // 导出病历模板
  async doctortexport() {
    const result = (await doctortexport({
      responseType: "arraybuffer",
    })) as any;
    if (result) {
      console.log("111");
      fileDownload(result.data, "患者列表模板.xls");
    } else {
      this.$message.warning("下载模板失败");
    }
  }
  // 导入模板
  async doctortimport() {
    const result = await doctortimport();
    if (result.success) {
      this.tableData = result.data;
      console.log(result.data);
    }
  }
  //////////////
  sumitBtn() {
    (this.$refs.file as any).dispatchEvent(new MouseEvent("click"));
  }
  // 触发上传材料文件
  sumitFile() {
    const inputFile = (this.$refs.file as any).files[0];
    const filename = inputFile.name;
    //此处应向后台请求 后台保存上传文件名称返回fileId作为文件标识
    // console.log(inputFile)
    this.file = {
      fileId: filename,
      file: inputFile,
    };
    // this.uploadFile(param);
  }
  //
  uploadFile() {
    if (this.file.fileId) {
      const formData = new FormData();
      formData.set("fileName", this.file.fileId);
      formData.set("file", this.file.file);
      doctortimport({
        formData,
      }).then(() => {
        this.$message.success("上传成功");
        this.file = {
          fileId: "",
          file: "",
        };
        this.cancelButton();
        //this.getFiles();
      });
    } else {
      this.$message.warning("请选择上传资料");
    }
  }

  /////////////////////
  //重置
  searchReset() {
    this.formList = {
      name: "", // 姓名
      age: "", // 年龄
      channel: "", // 渠道
      disease: "", // 疾病诊断
      diseaseyear: "", // 病程
    };
  }
  //搜索
  async searchButton() {
    const temp = {} as any;
    const Api: any = this.formList; //重新定义了下循环的对象
    for (const key in Api) {
      if (Api[key]) {
        temp[key] = Api[key];
      }
    }
    const result = await doctorsPatientList({ ...temp, ...this.form });
    if (result.success) {
      this.tableData = result.data;
      this.form.cur_page = 1;
      this.totalSize = result.page.total_size;
    } else {
      this.$message.success("搜索失败");
    }
  }
  editClick(v: number) {
    console.log(v);
    // this.$refs.edit.dialogFormVisible = true;
    //   this.$refs.edit.form = v;
  }
}
